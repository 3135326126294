import dynamic from 'next/dynamic'
import styled from 'styled-components'

import { breakpoints, colors } from '@/styles'

const ApplicationDownloadBase = dynamic(() => import('./base'))

const ApplicationDownloadContainer = styled.div`
  background-color: ${colors.background};
  min-height: 900px;
  @media (max-width: ${breakpoints.desktop}) {
    min-height: 750px;
  }
  @media (max-width: ${breakpoints.tabletLandscape}) {
    min-height: 1100px;
  }
  @media (max-width: ${breakpoints.phoneMax}) {
    min-height: 900px;
  }
`

const ApplicationDownload = props => {
  return (
    <ApplicationDownloadContainer>
      <ApplicationDownloadBase {...props} />
    </ApplicationDownloadContainer>
  )
}

export default ApplicationDownload
